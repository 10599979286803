<template>
  <div>
    <!-- 默认 万濠 -->
    <g-1 v-if="companyConfig.pcguodutype==1" />
    <!-- 地球 -->
    <g-3 v-if="companyConfig.pcguodutype==2" />
    <!-- 红车 -->
    <g-2 v-if="companyConfig.pcguodutype==3" />

  </div>
</template>

<script>
import G1 from "./guide1.vue";
import G2 from "./guide2.vue";
import G3 from "./guide3.vue";
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  components: { G1, G2, G3 },
  data() {
    return {
      a: 1,
    };
  },
  computed: {
    ...mapState(["userInfo", "companyConfig"]),
  },
  created() {
    this.getCompanyConfig()
    this.getAccountMoney();
  },
  mounted() {},
  methods: {
    getCompanyConfig(){
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getcompanyconfig",obj).then((res) => {
        if (res.Status) {
          let companyInfo = JSON.parse(res.Msg);

          this.$store.commit("SETCOMPANYCONFIG", companyInfo);
          
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
    // 获取账户信息
    getAccountMoney() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getmoneyinfo", obj).then((res) => {
        if (res.Status) {
          let resobj = JSON.parse(res.Msg);
          console.log(resobj, "账户信息");
          this.$store.commit("setAccInfo", resobj);
          this.accMoney = resobj;
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    gohome() {
      this.$router.push("/home");
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
 
 
<template>
  <div class="agree-body">
    <div class="agree-main">
      <div class="agree-box">
        <div class="agree-top"></div>
        <div class="agree-info">
          <h3 class="h3">用户协议</h3>
          <div class="agree-content">
            <div class="agree-text">
              <div class="agree-show">
                <p><strong>致会员：</strong></p>

                <div class="win_info" id="cont">
                  <ul>
                    <li>● 01. 为避免出现争议，请您务必在下注之后查看「下注状况」。</li>
                    <li>● 02. 任何投诉必须在开奖之前，本系统不接受任何开奖之后的投诉。</li>
                    <li>● 03. 公布赔率时出现的任何打字错误或非故意人为失误，所有（相关）注单一律不算。</li>
                    <li>● 04. 公布之所有赔率为浮动赔率，下注时请确认当前赔率及金额，下注确认后一律不能修改。</li>
                    <li>● 05. 开奖后接受的投注，一律视为投机漏洞，[本局注单一律不返还本金及盈利]，敬请会员遵守游戏规则。</li>
                    <li>● 06. 若本后台发现客户以不正当的手法投注或投注注单不正常，后台将有权「取消」相应之注单，客户不得有任何异议。</li>
                    <li>● 07. 如因软件或线路问题导致交易内容或其他与账号设定不符合的情形，请在开奖前立即与本后台联络反映问题，否则本后台将以资料库中的数据为准。</li>
                    <li>● 08. 倘若发生遭黑客入侵破坏行为或不可抗拒之灾害致网站故障或资料损坏、数据丢失等情况，后台将以资料库数据为依据。</li>
                    <li>● 09. 各级管理人员及客户必须对本系统各项功能进行了解及熟悉，任何违反正常使用的操作，后台概不负责。</li>
                    <li>● 10. 请认真了解各款彩票游戏规则。</li>
                    <li class="ftcolor_red">● 11. 如果会员信用额度超额或者为负数引起的争议，一律以公司处理为准。</li>
                    <li>● 12. 客户有责任确保自己的账户及密码保密，如果因客户的账户、密码简单，或因泄露导致被盗用，造成的损失由客户本人承担；同时应立即通知本公司，并更改其个人详细资料。</li>
                    <li>● 13. 若官方开奖错误导致本系统采集数据同时出错情况下当期错误的所有注单以官方网站更改后的数据为标准重新结算！在此特别声明，客户不得有任何异议。</li>
                    <li>以上协议解释权归本系统所有。</li>
                   
                  </ul>
                </div>
                <div class="tongyi-fan">
 
                  <h3 class="red" v-if="userInfo.lastloginip">上次登录:【{{userInfo.lastloginclient=='(P)'?'PC':'手机端'}}】 {{userInfo.lastlogintime|substrSome}}{{userInfo.lastloginarea}}【{{userInfo.lastloginip}}】</h3>
                  <a href="javascript:;" @click="goback">不同意</a>
                  <a href="javascript:;" @click="gohome">同意</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.getAccountMoney();
  },
  mounted() {},
  methods: {
    // 获取账户信息
    getAccountMoney() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("getmoneyinfo", obj).then((res) => {
        if (res.Status) {
          let resobj = JSON.parse(res.Msg);
          console.log(resobj, "账户信息");
          this.$store.commit("setAccInfo", resobj);
          this.accMoney = resobj;
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    gohome() {
      this.$router.push("/home");
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.tongyi-fan a {
  display: inline-block;
  margin: 0 20px;
  width: 66px;
  height: 27px;
  /* background: url("../assets/tab3.png") no-repeat 0 0; */
  background: linear-gradient(to bottom, #1268df 1%, #0f4b9e);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 27px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}
.tongyi-fan {
  padding-bottom: 5px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  text-align: center;
}
.ftcolor_red {
  color: red;
}
strong {
  font-weight: bold;
}
.agree-show p,
.agree-show li {
  margin-top: 3px;
  line-height: 20px;
}
.agree-show {
  padding: 14px 40px 0px 40px;
}
.agree-text {
  min-height: 350px;
  width: 770px;
  background: #fff;
}
.agree-content {
  /* background: url("../assets/user_winshadow.png") right 0 repeat-y; */
  width: 100%;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.agree-info .h3 {
  height: 51px;
  /* background: url("../assets/user_win.png") no-repeat 0 0; */
  background: linear-gradient(to bottom, #1268df 1%, #0f4b9e);
  font-size: 20px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  line-height: 51px;
}
.agree-info {
  padding-bottom: 19px;
  /* background: url("../assets/user_win.png") no-repeat 0 bottom; */
}
.agree-top {
  height: 30px;
}
.agree-box {
  padding-bottom: 80px;
  width: 778px;
  margin: 0 auto;
}
.agree-main {
  width: 100%;
  height: 100%;
  min-height: 450px;
  /* background: url("../assets/user_background.jpg"); */
  background-size: cover;
}
.agree-body {
  height: 100vh;
  font-size: 12px;
  background: url("../assets/guidebg.jpg") no-repeat 0 0;
  background-size: 100% 100%;
}
</style>